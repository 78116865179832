.mod--map {
  padding-bottom: 100px;
  @include breakpoint-max(map-get($breakpoints, small)) {
    padding-bottom: 50px;
  }

  &__wrapper {
    height: 100vh;
    position: relative;

    .mod--map__map {
      width: 100%;
      height: 100%;
      position: relative;

      .visible-image {
        width: auto;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &__content {
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        .placeholder-image {
          width: auto;
          height: 100%;
          visibility: hidden;
        }
      }
    }

    .mod--map__legend {
      position: absolute;
      left: 130px;
      top: 100px;
      width: 300px;
      background: white;
      z-index: 0;

      @include breakpoint-max(map-get($breakpoints, medium)) {
        left: 20px;
        right: auto;
        width: 200px;
      }
      
      ol {
        text-indent: 0px;
        padding-left: 50px;
      }

      li {
        font-size: 17px;
        font-weight: 500;
        letter-spacing: 1.7px;
        list-style-type: decimal;
        list-style-position: outside;
        margin-bottom: 10px;

        span {
          font-family: map-get($fonts, default-serif);
          font-size: 18px;
          font-weight: 100;
          line-height: 30px;
        }

        @include breakpoint-max(map-get($breakpoints, small)) {
          font-size: 15px;
          margin-bottom: 5px;

          span {
            font-size: 16px;
          }
        }
      }
    }
    
    .marker {
      label:not(.logo) {
        width: 25px;
        height: 25px;
        background: black;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        color: white;
        position: relative;

        .line {
          width: 14px;
          height: 1px;
          background: white;
          display: block;
          top: 50%;
          left: 50%;
          position: absolute;
          transition: all 0.1s ease-out;
          transform: translate(calc(-50% + 0.5px), calc(-50% - 0.5px));

          // &:first-of-type {
          //   transform: translate(calc(-50% + 1px), calc(-50% + 1px));
          // }

          &:last-of-type {
            transform: translate(calc(-50% + 0.5px), calc(-50% - 0.5px)) rotate(-90deg);
          }
        }
      }

      label.logo {
        cursor: pointer;

        img {
          width: 150px;
        }
      }

      input {
        display: none;

        &:checked ~ .entry {
          display: block;
        }

        &:checked ~ label {
          .line:last-of-type {
            transform: translate(calc(-50% + 0.5px), calc(-50% - 0.5px)) rotate(0deg);
          }
        }
      }

      .entry {
        display: none;
        width: 300px;
        padding: 15px;
        margin: 10px;
        background: black;
        color: white;
        z-index: 4;
        left: 0;
        position: absolute;

        @include breakpoint-max(map-get($breakpoints, small)) {
          transform: translateX(-45%);
          width: 250px;

          h4 {
            font-size: 14px !important;
            line-height: 1.1 !important;
          }
  
          p {
            font-size: 12px !important;
            line-height: 1.1 !important;
          }
        }

        h4 {
          font-size: 16px;
          line-height: 1.3;
          text-transform: uppercase;
          font-family: map-get($fonts, default-sans-serif);
          font-weight: 500;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          line-height: 1.3;
          font-family: map-get($fonts, default-serif);
          font-weight: 100;
          margin-bottom: 0;
        }
      }
    }
  }
}