$theme-name: 'default';

& {
  &.theme--#{$theme-name} {
    background: map-get($colors, white);
    border-radius: map-get($styling, default-border-radius);
    overflow: hidden;
    margin: 0 #{map-get($grid, gap) / 2};
    max-width: calc(25% - #{map-get($grid, gap) / 2});
    box-shadow: map-get($styling, default-shadow);
  }

  &__media {
    figure {
      .default-figure__image-wrapper {
        position: relative;
        height: 150px;
        width: 100%;
      }

      figcaption {
        padding: $default-spacing #{$default-spacing * 2.5};
      }
    }
  }

  &__content {
    padding: $default-spacing * 2.5;

    &__overheadline {
      font-family: map-get($fonts, default-sans-serif);
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.3rem;
      color: map-get($colors, grey-light);
      margin-bottom: $default-spacing;
    }

    &__headline {
      font-family: map-get($fonts, default-serif);
      font-size: 2rem;
      font-weight: 700;
      color: map-get($colors, black);
      margin-bottom: $default-spacing * 2;
    }

    &__text {
      font-size: 1.6rem;
      line-height: 1.6;
      margin-bottom: $default-spacing * 2;
    }

    &__anchor {
      color: map-get($colors, white);
      font-size: 1.2rem;
      font-family: map-get($fonts, default-sans-serif);
      border-radius: map-get($styling, default-border-radius);
      background: map-get($colors, black);
      border: map-get($styling, default-border) map-get($colors, black);
      transition: map-get($animation, default-transition);

      &:hover {
        background: map-get($colors, white);
        color: map-get($colors, black);
        border: map-get($styling, default-border) map-get($colors, black);
      }
    }
  }
}
