.modal {
  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    z-index: 9999;
    display: none;
  }

  &__content {
    background: white;
    max-width: 600px;
    height: calc(100% - 200px);
    overflow: auto;
    margin: 100px auto;
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    @include breakpoint-max(map-get($breakpoints, medium)) {
      margin: 0 auto;
      height: 100%;
    }

    input {
      margin-bottom: 20px;
    }

    p {
      font-weight: 300;
    }

    @include breakpoint-max(map-get($breakpoints, medium)) {
      h3 {
        font-size: 20px;
      }

      input {
        font-size: 16px;
        padding: 0 10px;
        line-height: 32px;
      }
    }
  }
  
  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    height: 15px;
    width: 15px;

    span {
      position: absolute;
      width: 15px;
      height: 1px;
      top: 50%;
      left: 50%;
      background: map-get($colors, black);

      &:first-of-type {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:last-of-type {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &:target,
  &.shown {
    display: block;
  }
}
