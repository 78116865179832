footer {
  padding: #{$default-spacing * 10} map-get($default-side-padding, desktop);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .footer__icons {
    ul {
      display: flex;
      flex-wrap: nowrap;
      padding: 0;
    }

    li {
      list-style-type: none;
      max-width: 200px;
      text-indent: 0;
      padding: 0 10px;
      display: flex;
      align-content: stretch;
      flex-wrap: wrap;
      margin-right: 50px;
    }

    p {
      font-size: 14px;
      margin-bottom: 20px;
    }

    img {
      max-width: 50px;
      max-height: 70px;
      align-self: flex-end;
    }
  }

  .footer__text {
    margin-bottom: 100px;

    @include flex-basis-fix(100%);

    @include breakpoint-max(map-get($breakpoints, small)) {
      margin-bottom: 60px;
    }

    h3 {
      font-size: 17px;
      letter-spacing: 1.7px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    p {
      font-size: 36px;
      line-height: 45px;
      letter-spacing: 0.35px;
      font-family: map-get($fonts, default-serif);
      font-weight: 100;
      margin-bottom: 40px;

      @include breakpoint-max(map-get($breakpoints, small)) {
        font-size: 28px;
        line-height: 38px;
      }
    }

    a p {
      font-family: map-get($fonts, default-sans-serif);
      font-weight: 500;
    }

    @include breakpoint-max(map-get($breakpoints, small)) {
      a.btn {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }

  .footer__address {
    @include flex-basis-fix(70%);

    @include breakpoint-max(map-get($breakpoints, small)) {
      @include flex-basis-fix(100%);
    }

    address p {
      margin-bottom: 0;
      font-size: 17px;
      line-height: 25px;
      text-transform: uppercase;
      letter-spacing: 1.7px;
    }
  }

  .org--social-links {
    @include flex-basis-fix(30%);

    @include breakpoint-max(map-get($breakpoints, small)) {
      @include flex-basis-fix(100%);

      text-align: left;
      padding-top: 40px;
    }

    text-align: right;

    h3 {
      font-size: 17px;
      letter-spacing: 1.7px;
      line-height: 25px;
      margin-bottom: 32px;
      text-transform: uppercase;
    }

    &__holder {
      justify-content: flex-end;

      @include breakpoint-max(map-get($breakpoints, small)) {
        justify-content: flex-start;
      }

      li:last-of-type {
        margin-right: 0;
      }
    }
  }

  .org--footer-links {
    @include flex-basis-fix(100%);

    &__holder {
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      height: 100%;

      li {
        display: inline-block;
      }
    }

    &__anchor p {
      font-size: 16px;
      display: inline-block;
      margin-bottom: 0;
    }
  }

  @include breakpoint-max(map-get($breakpoints, medium)) {
    padding: 60px map-get($default-side-padding, medium) #{$default-spacing * 4};

    .org--social-links__holder {
      flex-wrap: wrap;

      li {
        margin-bottom: 52px;
      }

      .mol--social-link__icon {
        display: inline-block;
      }

      p {
        display: block !important;
      }
    }
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: #{$default-spacing * 4} map-get($default-side-padding, small);

    .org--social-links__holder {
      flex-wrap: wrap;
      padding-bottom: 80px;

      li {
        margin-bottom: #{$default-spacing * 4};
      }

      .mol--social-link__icon {
        margin-bottom: #{$default-spacing * 2};
      }

      p {
        display: block !important;
        margin-bottom: #{$default-spacing * 2};
      }
    }
  }
}
