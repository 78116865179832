.itm--page-ref-item {
  height: 500px;
  position: relative;

  &__media {
    height: 100%;
    width: 100%;
    overflow: hidden;

    figure {
      height: 100%;
      width: 100%;
    }
  }

  &__content {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__info {
    align-self: center;
    text-align: center;
    color: map-get($colors, white);
    width: 100%;

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
