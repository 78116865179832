.mod--wine-list {
  padding: #{$default-spacing * 10} map-get($default-side-padding, desktop);
  // grid-column-gap: $default-spacing * 2;
  // grid-row-gap: 15vh;

  @include breakpoint-max(map-get($breakpoints, medium)) {
    padding: #{$default-spacing * 5} map-get($default-side-padding, medium);
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: #{$default-spacing * 5} map-get($default-side-padding, small);
  }

  &__categories {
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
  }

  &__category {
    width: 100%;

    &__content {
      padding-top: 40px;
      width: 100%;
      order: 2;
      display: none;

      @include breakpoint-max(map-get($breakpoints, small)) {
        order: 0;
      }

      &.shown {
        display: block;
      }
    }

    &__teaser {
      order: 1;
      display: flex;
      flex-wrap: nowrap;
      //   width: 33.33333%;
      justify-content: flex-start;
      flex-direction: column;

      @include flex-basis-fix(33.33333%);

      @include breakpoint-max(map-get($breakpoints, small)) {
        @include flex-basis-fix(100%);

        order: 0;
        margin-top: 50px;
      }

      &__icon {
        display: flex;
        justify-content: center;

        @include breakpoint-max(map-get($breakpoints, small)) {
          padding-top: 40px;

          img {
            width: 80px;
            height: auto;
          }
        }
      }

      &__image__wrapper {
        height: 60vh;
        display: block;

        @include breakpoint-max(map-get($breakpoints, small)) {
          height: 40vh;
        }

        .mol--gallery-gate {
          height: 100%;
        }
      }

      span.line {
        height: 200px;
        width: 1px;
        display: block;
        background: map-get($colors, black);
        margin: 30px auto 0;

        @include breakpoint-max(map-get($breakpoints, small)) {
          height: 100px;
        }
      }

      &__description {
        padding-top: 40px;
        padding-right: 15%;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        @include breakpoint-max(map-get($breakpoints, small)) {
          padding-right: 0;
        }

        p {
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.18px;
          font-weight: 300;
          font-family: map-get($fonts, default-serif);
        }

        button {
          font-size: 36px;
          line-height: 42px;
          text-transform: uppercase;
          font-family: map-get($fonts, default-sans-serif);
          padding: 0;
          appearance: none;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          @include breakpoint-max(map-get($breakpoints, small)) {
            font-size: 28px;
            line-height: 34px;
          }

          svg {
            width: 24px;
            height: 24px;
            transition: all 0.3s ease-out;
          }

          &.active {
            svg {
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    &__wine {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 40px 0;
      border-bottom: 2px solid black;

      @include breakpoint-max(map-get($breakpoints, small)) {
        padding: 20px 0;
      }

      &:first-of-type {
        border-top: 2px solid black;
      }

      &__intro {
        @include flex-basis-fix(100%);

        display: flex;
        justify-content: space-between;
        cursor: pointer;
        align-items: flex-end;

        @include breakpoint-max(map-get($breakpoints, small)) {
          flex-wrap: wrap;

          p {
            font-size: 22px !important;

            span {
              margin-right: 8px;
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }

        p {
          font-size: 31px;
          // line-height: 40px;
          letter-spacing: 2.48px;
          text-transform: uppercase;
        }

        &__number,
        &__year {
          font-weight: 100;
          margin-right: 20px;
        }

        &__sort {
          font-weight: 500;
          margin-right: 10px;
        }

        &__taste {
          font-weight: 100;
          margin-right: 10px;
        }

        &__alc {
          font-size: 14px;
          letter-spacing: 1.12px;
        }
      }

      &__content {
        @include flex-basis-fix(100%);

        display: flex;
        flex-wrap: wrap;
      }

      &__image {
        @include flex-basis-fix(40%);

        @include breakpoint-max(map-get($breakpoints, small)) {
          @include flex-basis-fix(100%);

          max-height: 60vh;
        }

        figure {
          height: 100%;
          max-height: 70vh;
        }
      }

      &__description {
        @include flex-basis-fix(60%);

        display: flex;
        padding: 20px;

        @include breakpoint-max(map-get($breakpoints, small)) {
          @include flex-basis-fix(100%);

          p {
            font-size: 24px !important;
            line-height: 32px !important;
          }
        }

        p {
          font-family: map-get($fonts, default-serif);
          font-size: 36px;
          line-height: 43px;
          font-weight: 300;
          align-self: center;
        }
      }
    }
  }

  .mod--wine-list__category__wine__intro__more {
    font-size: 18px;
    letter-spacing: 0.72px;
    font-weight: 500;
    cursor: pointer;
    // line-height: 40px;
    align-self: flex-end;
    display: flex;
    flex-wrap: nowrap;

    svg {
      margin-left: 5px;
      width: 17px;
      height: 17px;
      align-self: center;
      transition: all 0.3s ease-out;
    }
  }

  input {
    // position: absolute;
    // left: -1000px;
    // top: -1000px;
    // width: 1px;
    // height: 1px;
    // visibility: hidden;
    display: none;
  }

  input + .mod--wine-list__category__content,
  input ~ .mod--wine-list__category__wine__content {
    // transition: all 0.3s ease-out;
    max-height: 0;
    height: auto;
    visibility: hidden;
    overflow: hidden;
  }

  input:checked + .mod--wine-list__category__content,
  input:checked ~ .mod--wine-list__category__wine__content {
    max-height: 10000px;
    visibility: visible;
  }

  input:checked ~ .mod--wine-list__category__wine__content {
    min-height: 50vh;
  }
}

input:checked ~ label {
  svg {
    transform: rotate(90deg);
  }
}
