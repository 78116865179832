$theme-name: 'eimermann';

&.theme--#{$theme-name} {
  .mod--rich-text__content {
    padding: 80px map-get($default-side-padding, desktop) 80px;

    figure {
      // margin: 4rem 0;

      figcaption {
        font-size: 1.6rem;
        font-family: map-get($fonts, default-sans-serif);
        color: map-get($colors, accent1);
        line-height: 1.6;
        margin-top: 1.5rem;
        margin-bottom: 40px;
      }
    }

    p {
      // font-weight: 300;
    }

    h1 {
      font-size: 60px;
      font-weight: 300;
      // text-transform: uppercase;
      margin-top: 160px;
    }

    h2 {
      font-size: 36px;
      font-weight: 300;
      // text-transform: uppercase;
      margin-top: 30px;
    }

    h3 {
      font-size: 26px;
      font-weight: 300;
      text-transform: uppercase;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    a {
      transition: map-get($animation, default-transition);

      &:hover,
      &:target {
        color: map-get($colors, accent1);

        i {
          color: map-get($colors, accent1);
        }
      }

      i {
        transition: map-get($animation, default-transition);
        color: map-get($colors, black);
      }
    }

    @include breakpoint-max(map-get($breakpoints, medium)) {
      padding: 40px 0 40px;
    }

    @include breakpoint-max(map-get($breakpoints, small)) {
      padding: 40px map-get($default-side-padding, small) 40px;
    }
  }
}
