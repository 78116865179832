$theme-name: 'default';

& {
  &.theme--#{$theme-name} {
    overflow: hidden;
    margin: 0;

    position: relative;
  }

  &__media {
    figure {
      // height: 100%;

      .default-figure__image-wrapper {
        position: relative;
        height: 50vh;
        width: 100%;
      }

      figcaption {
        padding: $default-spacing #{$default-spacing * 2.5};
      }
    }
  }

  &__content {
    padding: $default-spacing * 10 $default-spacing * 5;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;

    &__overheadline {
      font-family: map-get($fonts, default-sans-serif);
      text-transform: uppercase;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 1.6;
      color: map-get($colors, black);
      margin-bottom: $default-spacing;
    }

    &__headline {
      font-family: map-get($fonts, default-serif);
      font-size: 4rem;
      font-weight: 700;
      color: map-get($colors, black);
      margin-bottom: $default-spacing * 2;
    }

    &__text {
      font-size: 2rem;
      line-height: 1.6;
      margin-bottom: $default-spacing * 2;
    }

    &__anchor {
      color: map-get($colors, white);
      font-size: 1.2rem;
      font-family: map-get($fonts, default-sans-serif);
      border-radius: map-get($styling, default-border-radius);
      background: map-get($colors, black);
      border: map-get($styling, default-border) map-get($colors, black);
      transition: map-get($animation, default-transition);

      &:hover {
        background: map-get($colors, white);
        color: map-get($colors, black);
        border: map-get($styling, default-border) map-get($colors, black);
      }
    }
  }
}
