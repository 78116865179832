.mod--newsletter {
  padding: #{$default-spacing * 10} map-get($default-side-padding, desktop);
  // grid-column-gap: $default-spacing * 2;
  // grid-row-gap: 15vh;

  @include breakpoint-max(map-get($breakpoints, medium)) {
    padding: #{$default-spacing * 5} map-get($default-side-padding, medium);
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: #{$default-spacing * 5} map-get($default-side-padding, small);
  }

  &__content {
    display: flex;
    flex-wrap: wrap;

    &__text,
    &__form {
      @include flex-basis-fix(50%);

      @include breakpoint-max(map-get($breakpoints, small)) {
        @include flex-basis-fix(100%);
      }
    }

    &__text {
      padding-right: 40px;

      @include breakpoint-max(map-get($breakpoints, small)) {
        padding-right: 0;
      }

      h3 {
        font-size: 17px;
        letter-spacing: 1.7px;
        line-height: 1.3;
        margin-bottom: 25px;
        text-transform: uppercase;
      }

      p {
        font-family: map-get($fonts, default-serif);
        font-size: 36px;
        line-height: 45px;
        letter-spacing: 0.35px;
        font-weight: 100;

        @include breakpoint-max(map-get($breakpoints, small)) {
          font-size: 28px;
          line-height: 38px;
        }
      }
    }

    &__form {
      padding-left: 40px;

      @include breakpoint-max(map-get($breakpoints, small)) {
        padding-left: 0;
      }

      form {
        display: flex;
        flex-wrap: wrap;

        fieldset {
          margin-bottom: 40px;

          @include breakpoint-max(map-get($breakpoints, small)) {
            margin-bottom: 20px;
          }
        }

        .mol--newsletter-form__email {
          order: 1;
          max-width: calc(75% - 20px);
          margin-right: 20px;

          @include flex-basis-fix(calc(75% - 20px));

          @include breakpoint-max(map-get($breakpoints, small)) {
            @include flex-basis-fix(100%);

            margin-right: 0;
            max-width: 100%;
          }
        }

        .mol--newsletter-form__submit {
          order: 2;
          
          @include flex-basis-fix(25%);

          @include breakpoint-max(map-get($breakpoints, small)) {
            @include flex-basis-fix(100%);

            max-width: 100%;
          }

          button {
            padding: 0 10px;
            margin-right: 0;
            width: 100%;
          }
        }

        .mol--newsletter-form__clearance {
          order: 3;
        }
      }
    }
  }
}