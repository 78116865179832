.mod--further-action {
  position: fixed;
  top: 50%;
  left: 0;
  background: map-get($colors, black);
  padding: 30px 60px 30px 130px;
  max-width: 400px;
  text-align: left;
  z-index: 9990;

  @include breakpoint-max(map-get($breakpoints, medium)) {
    padding: 30px 60px 30px map-get($default-side-padding, medium);
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: 30px 60px 30px map-get($default-side-padding, small);
  }

  &.hidden {
    display: none;
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    height: 15px;
    width: 15px;

    span {
      position: absolute;
      width: 15px;
      height: 1px;
      top: 50%;
      left: 50%;
      background: map-get($colors, white);

      &:first-of-type {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:last-of-type {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  p {
    font-size: 17px;
    line-height: 25px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  button.btn {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    border-radius: 0;
    min-width: 0;
    text-align: left;
    color: white;

    &:hover,
    &:target {
      p {
        color: map-get($colors, white) !important;
      }
    }

    &::after {
      display: none;
    }
    
    p {
      line-height: 1.1;
    }
  }
}
