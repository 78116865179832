.mod--instagram-feed {
  padding: 80px map-get($default-side-padding, desktop) 80px;

  @include breakpoint-max(map-get($breakpoints, medium)) {
    padding: 40px map-get($default-side-padding, medium) 40px;
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: 40px map-get($default-side-padding, small) 40px;
  }

  iframe {
    width: calc(100vw - #{map-get($default-side-padding, desktop) * 2});
    height: calc(((100vw - #{map-get($default-side-padding, desktop) * 2}) / 3) * 2);
    // height: 200px;

    @include breakpoint-max(map-get($breakpoints, medium)) {
      width: calc(100vw - #{map-get($default-side-padding, medium) * 2});
      height: calc(((100vw - #{map-get($default-side-padding, medium) * 2}) / 3) * 2);
    }
  
    @include breakpoint-max(map-get($breakpoints, small)) {
      width: calc(100vw - #{map-get($default-side-padding, small) * 2});
      height: calc(((100vw - #{map-get($default-side-padding, small) * 2}) / 2) * 3);
    }
  }
}
