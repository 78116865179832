.org--social-links {
  // max-width: $max-content-width;
  margin: 0 auto;

  ul {
    text-indent: 0;
    padding-left: 0;
    display: flex;
    justify-content: center;
  }
}
