/* stylelint-disable value-list-comma-newline-after */

/* ----- Messina Sans Condensed ----- */

/* Light */
@font-face {
  font-family: 'Messina Sans Condensed';
  src:
    url('/assets/fonts/themes/eimermann/messina-sans-condensed-light/messina-sans-condensed-light.woff2') format('woff2'),
    url('/assets/fonts/themes/eimermann/messina-sans-condensed-light/messina-sans-condensed-light.woff') format('woff');
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

/* SemiBold */
@font-face {
  font-family: 'Messina Sans Condensed';
  src:
    url('/assets/fonts/themes/eimermann/messina-sans-condensed-semibold/messina-sans-condensed-semibold.woff2') format('woff2'),
    url('/assets/fonts/themes/eimermann/messina-sans-condensed-semibold/messina-sans-condensed-semibold.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

/* ----- Messina Serif ----- */

//* Light */
@font-face {
  font-family: 'Messina Serif';
  src:
    url('/assets/fonts/themes/eimermann/messina-serif-light/messina-serif-light.woff2') format('woff2'),
    url('/assets/fonts/themes/eimermann/messina-serif-light/messina-serif-light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
