@mixin breakpoint-max($val) {
  @media only screen and (max-width: $val) {
    @content;
  }
}

@mixin height($step) {
  &.window-height--#{ $step } {
    // min-height: #{calc(100vh / 12 * #{$step})};
    // max-height: #{calc(100vh / 12 * #{$step})};

    // .itm--teaser-text {
    //   min-height: #{calc(100vh / 12 * #{$step})};

    //   @include breakpoint-max(map-get($breakpoints, small)) {
    //     min-height: 40vh;
    //     height: auto;
    //   }
    // }

    ul.glide__slides {
      margin-bottom: 0;
    }

    .itm--teaser-image {
      min-height: #{calc(100vh / 12 * #{$step})};
      align-self: stretch;

      @include breakpoint-max(map-get($breakpoints, small)) {
        min-height: 40vh;
        height: auto;
      }

      &__media {
        // object-fit: cover;
        // height: 100%;

        picture {
          height: #{calc(100vh / 12 * #{$step})};
          min-height: 100%;
          width: 100%;
          display: block;

          @include breakpoint-max(map-get($breakpoints, small)) {
            height: 100%;
          }
        }

        &.teaser-image--padding {
          picture {
            height: #{calc((100vh / 12 * #{$step}) - 350px)};
            min-height: 100%;
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
}

@mixin flex-basis-fix($val) {
  flex-basis: $val;
  max-width: $val; /* ie fix */
}

@each $name, $background in $colors {
  .background-color--#{$name} {
    background: #{$background};

    .btn:hover {
      color: $background;
    }
  }
};

@each $name, $background in $dark-colors {
  .background-color--#{$name} {
    background: #{$background};
    color: map-get($colors, white);

    .btn {
      @extend %button-bright;

      &:hover {
        color: $background;
      }
    }

    a {
      border-color: map-get($colors, white);
      color: map-get($colors, white);
    }

    svg {
      fill: map-get($colors, white);
    }

    input[type="text"],
    input[type="email"] {
      appearance: none;
      background: none;
      border: 2px solid white;
      color: white;
      border-radius: 30px;
      padding: 0 20px;
      line-height: 56px;
      font-size: 18px;
      font-family: map-get($fonts, default-sans-serif);
      display: block;
      width: 100%;
      outline: none;

      &::placeholder {
        color: white;
      }
    }

    .glide__controls {
      button.glide__bullet {
        background: black !important;
        border: 2px solid white !important;

        &--active,
        &:hover,
        &:focus {
          background: white !important;
        }
      }
    }
  }
};
