.main-navigation {
  transition: all 0.3s ease-out;

  &__holder {
    z-index: 9999;
    position: relative;
    padding: 20px map-get($default-side-padding, desktop);
    display: grid;
    grid-template-areas: "logo . . toggle";
    background: map-get($colors, black);

    @include breakpoint-max(map-get($breakpoints, medium)) {
      padding: 20px map-get($default-side-padding, medium);
    }

    @include breakpoint-max(map-get($breakpoints, small)) {
      padding: 20px map-get($default-side-padding, small);
    }
  }

  &__logo {
    width: 220px;
    grid-area: logo;

    a {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      div.logo {
        height: 45px;

        @include flex-basis-fix(45px);
      }

      div.slogan {
        @include flex-basis-fix(160px);
      }

      svg {
        transition: 0.3s all ease-out;
        fill: map-get($colors, white);
      }
    }
  }

  &__toggle {
    grid-area: toggle;
    width: 28px;
    height: 21px;
    justify-self: end;
    align-self: center;
    cursor: pointer;

    // @include breakpoint-max(map-get($breakpoints, medium)) {
    //   width: 16px;
    //   height: 14px;
    // }

    &__content {
      position: relative;
      height: 100%;
    }

    &__line {
      width: 28px;
      height: 2px;
      background: map-get($colors, white);
      position: absolute;
      transition: 0.3s all ease-out;

      // @include breakpoint-max(map-get($breakpoints, medium)) {
      //   width: 16px;
      //   height: 3px;
      // }

      &:nth-of-type(1) {
        top: 0;
        left: 0;
        // background: blue;
      }

      &:nth-of-type(2) {
        top: 9px;
        left: 0;
        // background: cyan;

        // @include breakpoint-max(map-get($breakpoints, medium)) {
        //   top: 6px;
        // }
      }

      &:nth-of-type(3) {
        bottom: 0;
        left: 0;
        // background: yellow;

        // @include breakpoint-max(map-get($breakpoints, medium)) {
        //   bottom: -1px;
        // }
      }
    }

    &.active {
      .main-navigation__toggle__line {
        &:nth-of-type(1) {
          transform: translateY(8px) rotate(45deg);
          // transform-origin: bottom left;
          width: 35px;
        }

        &:nth-of-type(2) {
          width: 0;
        }

        &:nth-of-type(3) {
          transform: translateY(-11px) rotate(-45deg);
          // transform-origin: top left;
          width: 35px;
        }
      }
    }
  }

  &__overlay {
    z-index: 9998;
    position: fixed;
    transform: translateX(100%);
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    transition: 0.5s all ease-in;
    padding-top: 150px;
    padding: 150px 100px 40px;
    display: grid;
    grid-template-areas:
      "header header header header"
      "menu menu menu menu"
      "footer footer footer footer";
    overflow-y: auto;

    @include breakpoint-max(map-get($breakpoints, medium)) {
      width: 75%;
      padding: 150px map-get($default-side-padding, medium) 40px;
    }

    @include breakpoint-max(map-get($breakpoints, small)) {
      width: 100%;
      padding: 150px map-get($default-side-padding, small) 40px;
    }

    &.active {
      transform: translateX(0);
    }

    .org--social-links {
      grid-area: header;
      bottom: 100px;
      // text-align: center;
      width: 100%;
      align-self: end;
      padding-bottom: 40px;

      h3 {
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 20px;
        letter-spacing: 1.7px;
        text-transform: uppercase;
      }

      @include breakpoint-max(map-get($breakpoints, medium)) {
        padding-bottom: 20px;
      }

      @include breakpoint-max(map-get($breakpoints, small)) {
        padding-bottom: 0;
        margin-bottom: 0;
      }

      &__holder {
        justify-content: flex-start;
      }
    }

    .org--main-menu {
      grid-area: menu;
      // justify-self: center;
      align-self: center;

      ul {
        padding-left: 0;
        text-indent: 0;

        @include breakpoint-max(map-get($breakpoints, small)) {
          margin-bottom: #{$default-spacing * 6};
        }
      }
    }

    @include breakpoint-max(map-get($breakpoints, small)) {
      padding-top: 100px;

      .org--social-links__holder {
        flex-wrap: wrap;

        .mol--social-link {
          text-align: center;
          
          &__icon {
            margin: 0 auto #{$default-spacing * 2};
          }
        }
      }
    }
  }

  &.overlay-active {
    .main-navigation__logo {
      svg {
        fill: map-get($colors, white);
      }
    }

    .main-navigation__toggle__line {
      background: map-get($colors, white);
    }
  }

  .mod--newsletter__content__form {
    max-width: 100%;
    padding-left: 0;

    @include flex-basis-fix(100%);
  }
}
