@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.mod--cards {
  @import 'theme/default.scss';

  display: flex;
  justify-content: center;

  .glide__slide {
    width: calc(25% - #{map-get($grid, gap) / 2});

    .art--card {
      max-width: 100%;
    }
  }
}
