.mod--package-teaser {
  padding: #{$default-spacing * 10} map-get($default-side-padding, desktop);
  // grid-column-gap: $default-spacing * 2;
  // grid-row-gap: 15vh;

  @include breakpoint-max(map-get($breakpoints, medium)) {
    padding: #{$default-spacing * 5} map-get($default-side-padding, medium);
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: #{$default-spacing * 5} map-get($default-side-padding, small);
  }
  
  &__item {
    .mol--gallery-gate {
      display: flex;
      flex-wrap: nowrap;
    }

    &.desktop {
      display: block;
    }

    &.mobile {
      display: none;
    }

    @include breakpoint-max(map-get($breakpoints, small)) {
      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;
      }
    }
  }
}
