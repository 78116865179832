button,
button:focus,
button:target {
  appearance: none;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}

.btn {
  padding: 0 #{$default-spacing * 7};
  border: map-get($styling, default-border) map-get($colors, black);
  border-radius: map-get($styling, default-border-radius);
  display: inline-block;
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin-right: 20px;

  p {
    font-size: 18px;
    line-height: 56px;
    letter-spacing: 0.72px;
    margin: 0;
    z-index: 2;
    // white-space: nowrap;
  }

  @include breakpoint-max(map-get($breakpoints, medium)) {
    min-width: 160px;

    p {
      font-size: 18px;
      line-height: 56px;
    }
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    min-width: 160px;

    p {
      font-size: 18px;
      line-height: 56px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    height: calc(100% + 4px);
    width: 0;
    background: map-get($colors, black);
    transition: all 0.3s ease-in-out;
    z-index: -1;
  }

  &:hover {
    color: map-get($colors, white);
    transition: color 0.5s ease-out;

    &::after {
      width: calc(100% + 4px);
    }
  }
}

%button-bright {
  @extend .btn;

  // color: inherit;
  border-color: map-get($colors, white);
  overflow: hidden;
  transition: 0.3s all ease-in-out;

  p {
    color: white;
  }

  &::after {
    background: map-get($colors, white);
  }

  &:hover {
    color: map-get($colors, black);

    p {
      color: map-get($colors, black);
    }

    &::after {
      width: calc(100% + 4px);
    }
  }
}
