@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.org--default-slider,
.slider--glide {
  height: 100%;
  width: 100%;

  .glide__track {
    height: 100%;
    width: 100%;
  }
}