.mol--social-link {
  text-align: center;
  cursor: pointer;
  list-style-type: none;
  text-indent: 0;
  margin-right: 20px;

  // @include breakpoint-max(map-get($breakpoints, small)) {
  //   margin-right: 0;
  // }

  &__icon {
    width: 48px;
    height: 48px;
    position: relative;
    margin-bottom: $default-spacing * 4;

    svg {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  p.mol--social-link__anchor-text {
    font-size: 16px;
    display: inline;
  }

  &:hover {
    p.mol--social-link__anchor-text {
      border-bottom: 2px solid map-get($colors, white);
    }
  }
}
