.itm--teaser-text {
  padding: 160px map-get($default-side-padding, desktop) 160px;
  display: flex;
  flex-wrap: wrap;

  &__content {
    width: 50%;

    @include breakpoint-max(map-get($breakpoints, small)) {
      width: 100%;
      padding-bottom: 40px;
      // order: 2;
    }

    h2 {
      font-size: 36px;
      line-height: 45px;
      letter-spacing: 0.36px;
      font-weight: normal;

      @include breakpoint-max(map-get($breakpoints, small)) {
        font-size: 28px;
        line-height: 37px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    h3 {
      font-family: map-get($fonts, default-sans-serif);
      font-size: 17px;
      line-height: 1.3;
      text-transform: uppercase;
      letter-spacing: 1.7px;
      margin-bottom: 25px;
    }

    p {
      font-size: 22px;
    }

    p + a.itm--teaser-text__anchor {
      margin-top: 20px;
    }
  }

  @include breakpoint-max(map-get($breakpoints, medium)) {
    padding: 80px map-get($default-side-padding, medium) 56px;
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: 80px map-get($default-side-padding, small) 56px;
    order: 2;
  }

  &__additionals {
    width: 50%;
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;

    @include breakpoint-max(map-get($breakpoints, small)) {
      width: 100%;
      justify-content: flex-start;
    }

    img {
      width: 116px;
      height: 116px;
      align-self: flex-end;

      @include breakpoint-max(map-get($breakpoints, small)) {
        width: 80px;
        height: 80px;
        margin-bottom: 40px;
      }
    }

    .itm--teaser-text__anchor,
    button.btn {
      align-self: flex-end;
      margin-top: 20px;
    }

    // .itm--teaser-text__anchor {
    //   &:last-of-type {
    //     margin-bottom: 0;
    //   }
    // }
  }
}
