html {
  font-size: 62.5%;
  // scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  text-decoration: none;
}

a,
a:visited,
a:focus,
a:target {
  color: inherit;
}

*[hidden] {
  display: none !important;
}