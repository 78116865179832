.mol--tags {
  text-indent: 0;
  padding-left: 0;

  li {
    display: inline-block;
    margin: 0 $default-spacing $default-spacing 0;
    list-style-type: none;
  }
}
