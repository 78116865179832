input[type="text"],
input[type="email"] {
  appearance: none;
  background: none;
  border: 2px solid black;
  border-radius: 30px;
  padding: 0 20px;
  line-height: 56px;
  font-size: 18px;
  font-family: map-get($fonts, default-sans-serif);
  display: block;
  width: 100%;
  outline: none;

  &::placeholder {
    color: black;
  }
}

input[type="submit"] {
  appearance: none;
  background: none;
  border: 2px solid black;
  background: map-get($colors, black);
  color: map-get($colors, white);
  border-radius: 30px;
  padding: 0 20px;
  line-height: 56px;
  font-size: 18px;
  font-family: map-get($fonts, default-sans-serif);
  display: block;
  width: 100%;
  outline: none;
  cursor: pointer;
  z-index: 0;
  position: relative;

  &::placeholder {
    color: white;
  }
}
