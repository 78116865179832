@import 'vendor/flexboxgrid.scss';

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .grid {
    display: flex;
  }

  .grid-span--,
  .grid-column-span-- {
    &1 {
      @include flex-basis-fix(calc((100% / 12) * 1));
    }

    &2 {
      @include flex-basis-fix(calc((100% / 12) * 2));
    }

    &3 {
      @include flex-basis-fix(calc((100% / 12) * 3));
    }

    &4 {
      @include flex-basis-fix(calc((100% / 12) * 4));
    }

    &5 {
      @include flex-basis-fix(calc((100% / 12) * 5));
    }

    &6 {
      @include flex-basis-fix(calc((100% / 12) * 6));
    }

    &7 {
      @include flex-basis-fix(calc((100% / 12) * 7));
    }

    &8 {
      @include flex-basis-fix(calc((100% / 12) * 8));
    }

    &9 {
      @include flex-basis-fix(calc((100% / 12) * 9));
    }

    &10 {
      @include flex-basis-fix(calc((100% / 12) * 10));
    }

    &11 {
      @include flex-basis-fix(calc((100% / 12) * 11));
    }

    &12 {
      @include flex-basis-fix(calc((100% / 12) * 12));
    }
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  // grid-template-columns: [line1] 1fr [line2] 1fr [line3] 1fr [line4] 1fr [line5] 1fr [line6] 1fr [line7] 1fr [line8] 1fr [line9] 1fr [line10] 1fr [line11] 1fr [line12] 1fr [line13];
  // grid-gap: map-get($grid, gap);

  &--centered {
    @extend .center-lg;
  }

  &-cluster {
    grid-template-rows: repeat(auto-fill, 10vh);
  }
}

.grid-justification {
  &--left {
    justify-content: flex-start;

    @extend .start-lg;
  }

  &--center {
    justify-content: center;

    @extend .center-lg;
  }

  &--right {
    justify-content: flex-end;

    @extend .end-lg;
  }
}

$grid-unit: 100% / 12;

.grid-span--,
.grid-column-span-- {
  &1 {
    // grid-column-end: span 1;
    // @include flex-basis-fix($grid-unit * 1);
    @extend .col-lg-1;

    padding-left: 0;
    padding-right: 0;
  }

  &2 {
    // grid-column-end: span 2;
    // @include flex-basis-fix($grid-unit * 2);
    @extend .col-lg-2;

    padding-left: 0;
    padding-right: 0;
  }

  &3 {
    // grid-column-end: span 3;
    // @include flex-basis-fix($grid-unit * 3);
    @extend .col-lg-3;

    padding-left: 0;
    padding-right: 0;
  }

  &4 {
    // grid-column-end: span 4;
    // @include flex-basis-fix($grid-unit * 4);
    @extend .col-lg-4;

    padding-left: 0;
    padding-right: 0;
  }

  &5 {
    // grid-column-end: span 5;
    // @include flex-basis-fix($grid-unit * 5);
    @extend .col-lg-5;

    padding-left: 0;
    padding-right: 0;
  }

  &6 {
    // grid-column-end: span 6;
    // @include flex-basis-fix($grid-unit * 6);
    @extend .col-lg-6;

    padding-left: 0;
    padding-right: 0;
  }

  &7 {
    // grid-column-end: span 7;
    // @include flex-basis-fix($grid-unit * 7);
    @extend .col-lg-7;

    padding-left: 0;
    padding-right: 0;
  }

  &8 {
    // grid-column-end: span 8;
    // @include flex-basis-fix($grid-unit * 8);
    @extend .col-lg-8;

    padding-left: 0;
    padding-right: 0;
  }

  &9 {
    // grid-column-end: span 9;
    // @include flex-basis-fix($grid-unit * 9);
    @extend .col-lg-9;

    padding-left: 0;
    padding-right: 0;
  }

  &10 {
    // grid-column-end: span 10;
    // @include flex-basis-fix($grid-unit * 10);
    @extend .col-lg-10;

    padding-left: 0;
    padding-right: 0;
  }

  &11 {
    // grid-column-end: span 11;
    // @include flex-basis-fix($grid-unit * 11);
    @extend .col-lg-11;

    padding-left: 0;
    padding-right: 0;
  }

  &12 {
    // grid-column-end: span 12;
    // @include flex-basis-fix($grid-unit * 12);
    @extend .col-lg-12;

    padding-left: 0;
    padding-right: 0;
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    &1,
    &2,
    &3,
    &4,
    &5,
    &6,
    &7,
    &8,
    &9,
    &10,
    &11,
    &12 {
      // grid-column-end: span 12;
      @include flex-basis-fix($grid-unit * 12);
    }
  }
}

.grid-row-span-- {
  &1 {
    grid-row-end: span 1;
    height: 10vh;
  }

  &2 {
    grid-row-end: span 2;
    height: 20vh;
  }

  &3 {
    grid-row-end: span 3;
    height: 30vh;
  }

  &4 {
    grid-row-end: span 4;
    height: 40vh;
  }

  &5 {
    grid-row-end: span 5;
    height: 50vh;
  }

  &6 {
    grid-row-end: span 6;
    height: 60vh;
  }

  &7 {
    grid-row-end: span 7;
    height: 70vh;
  }

  &8 {
    grid-row-end: span 8;
    height: 80vh;
  }

  &9 {
    grid-row-end: span 9;
    height: 90vh;
  }

  &10 {
    grid-row-end: span 10;
    height: 100vh;
  }
}

.grid-start-- {
  &1 {
    // grid-column-start: 1;
    // margin-left: $grid-unit * 0;

    // @extend .col-lg-offset-1;
  }

  &2 {
    // grid-column-start: 2;
    // margin-left: $grid-unit * 1;

    @extend .col-lg-offset-1;
  }

  &3 {
    // grid-column-start: 3;
    // margin-left: $grid-unit * 1;

    @extend .col-lg-offset-2;
  }

  &4 {
    // grid-column-start: 4;
    // margin-left: $grid-unit * 3;

    @extend .col-lg-offset-3;
  }

  &5 {
    // grid-column-start: 5;
    // margin-left: $grid-unit * 4;

    @extend .col-lg-offset-4;
  }

  &6 {
    // grid-column-start: 6;
    // margin-left: $grid-unit * 5;

    @extend .col-lg-offset-5;
  }

  &7 {
    // grid-column-start: 7;
    // margin-left: $grid-unit * 6;

    @extend .col-lg-offset-6;
  }

  &8 {
    // grid-column-start: 8;
    // margin-left: $grid-unit * 7;

    @extend .col-lg-offset-7;
  }

  &9 {
    // grid-column-start: 9;
    // margin-left: $grid-unit * 8;

    @extend .col-lg-offset-8;
  }

  &10 {
    // grid-column-start: 10;
    // margin-left: $grid-unit * 9;

    @extend .col-lg-offset-9;
  }

  &11 {
    // grid-column-start: 11;
    // margin-left: $grid-unit * 10;

    @extend .col-lg-offset-10;
  }

  &12 {
    // grid-column-start: 12;
    // margin-left: $grid-unit * 11;

    @extend .col-lg-offset-11;
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    &1,
    &2,
    &3,
    &4,
    &5,
    &6,
    &7,
    &8,
    &9,
    &10,
    &11,
    &12 {
      // grid-column-start: 1;
      margin-left: 0;
    }
  }
}

