.org--main-menu {
  margin-bottom: 100px;

  @include breakpoint-max(map-get($breakpoints, small)) {
    margin-bottom: 40px;
  }
  
  &__entry {
    font-size: 36px;
    line-height: 43px;
    margin-bottom: $default-spacing * 2;
    text-align: left;
    font-family: map-get($fonts, default-serif);
    list-style-type: none;
    font-weight: 100;
    
    a {
      border-bottom: 2px solid transparent;
    }

    &:hover,
    &.active {
      a {
        border-bottom: 2px solid white;
      }
    }
  }
}
