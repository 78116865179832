@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.mod--image-slider {
  @for $i from 1 through 12 {
    @include height($i);
  }

  padding: 100px map-get($default-side-padding, desktop) 100px;

  @include breakpoint-max(map-get($breakpoints, medium)) {
    padding: 60px map-get($default-side-padding, medium) 60px;
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    padding: 40px map-get($default-side-padding, small) 40px;
  }

  &__item {
    width: 100%;
    height: 100%;

    figure {
      height: 600px;

      @include breakpoint-max(map-get($breakpoints, medium)) {
        height: 400px;
      }
    
      @include breakpoint-max(map-get($breakpoints, small)) {
        height: 300px;
      }
    }

    ol,
    ul {
      text-indent: 0;
    }
  }

  .art--default-slide__media {
    height: auto !important;
  }

  .art--default-slide__description {
    padding: 0 40px;
    padding-top: 100px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    max-width: 900px;

    h3 {
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 1.7px;
      text-transform: uppercase;
    }

    p {
      font-size: 18px;
      letter-spacing: 0.9px;
      line-height: 25px;
      font-family: map-get($fonts, default-serif);
      font-weight: 300;
    }
  }

  .slider--glide {
    position: relative;
  }

  .glide__controls {
    top: 630px;
    left: 0;
    position: absolute;
    width: 100%;
    text-align: center;

    @include breakpoint-max(map-get($breakpoints, medium)) {
      top: 430px;
    }
  
    @include breakpoint-max(map-get($breakpoints, small)) {
      top: 330px;
    }

    .glide__bullet {
      display: inline-block;
      margin: 0 8px;
      width: 17px;
      height: 17px;
      border: 2px solid map-get($colors, black);
      border-radius: 8px;
      background: map-get($colors, white);

      &--active {
        background: map-get($colors, black);
      }
    }
  }

  .glide__arrows {
    position: absolute;
    left: 0;
    top: 300px;
    transform: translateY(-50%);
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    @include breakpoint-max(map-get($breakpoints, medium)) {
      top: 200px;
    }
  
    @include breakpoint-max(map-get($breakpoints, small)) {
      top: 150px;
    }

    button {
      height: 40px;
      width: 20px;
      position: relative;

      &:last-of-type {
        transform: rotate(180deg);
      }

      span.line {
        background: white;
        width: 25px;
        height: 2px;
        position: absolute;
        left: 0;

        &:first-of-type {
          transform: rotate(-45deg);
          transform-origin: center left;
          top: calc(50% + 0.5px);
        }

        &:last-of-type {
          transform: rotate(45deg);
          transform-origin: center left;
          top: calc(50% - 0.5px);
        }
      }
    }
  }
}
