.art--container {
  position: relative;

  &__headline__holder {
    padding: 130px map-get($default-side-padding, desktop) 80px;
    max-width: 65%;

    @include breakpoint-max(map-get($breakpoints, medium)) {
      padding: 40px map-get($default-side-padding, medium) 40px;
      max-width: 80%;

      h2 {
        font-size: 30px;
      }

      p {
        font-size: 16px;
      }
    }

    @include breakpoint-max(map-get($breakpoints, small)) {
      padding: 32px map-get($default-side-padding, small) 16px;
      max-width: 100%;

      h2 {
        font-size: 24px;

        + p {
          margin-top: 0 !important;
        }
      }

      p {
        font-size: 16px;
      }
    }

    h2 + p {
      margin-top: $default-spacing * 5;
    }
  }

  &.background-color--orange {
    color: map-get($colors, white);
  }

  justify-content: center;
}
