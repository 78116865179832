.org--footer-links {
  &__holder {
    text-indent: 0;
    padding-left: 0;
    list-style-type: none;

    .org--footer-links__anchor {
      font-size: 16px;
      margin-right: $default-spacing * 2.5;

      &:hover {
        border-bottom: 2px solid map-get($colors, white);
      }
    }
  }
}
