.itm--teaser-image {
  // align-self: flex-start;

  .teaser-image--padding {
    position: relative;
    padding: 200px 15% 150px;

    @include breakpoint-max(map-get($breakpoints, small)) {
      padding: 48px 20% 48px;
    }
  }

  @include breakpoint-max(map-get($breakpoints, small)) {
    order: 1;

    &__media:not(.teaser-image--padding) {
      max-height: 60vh;
    }
  }
}
